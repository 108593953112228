import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoaderCircle } from 'lucide-react';

const UserForm = ({ eventName, eventDate, countries }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [formStatus, setFormStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await fetch('/.netlify/functions/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data, eventName, eventDate }),
      });

      const result = await response.json();

      if (response.ok) {
        setFormStatus({ success: true, message: result.message });
        console.log('Form submitted successfully:', data);
      } else {
        setFormStatus({ success: false, message: result.message });
        console.error('Form submission failed');
      }
    } catch (error) {
      setFormStatus({ success: false, message: 'Error submitting form' });
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label htmlFor="firstName" className="form-label"><span>Your name</span><span className="text-red-500">*</span></label>
        <div className="grid grid-cols-2 gap-5">
          <div>
            <input {...register('firstName', { required: true })} className="form-input" placeholder="First" />
            {errors.firstName && <p className="text-red-500">First Name is required</p>}
          </div>
          <div>
            <input {...register('lastName', { required: true })} className="form-input" placeholder="Last" />
            {errors.lastName && <p className="text-red-500">Last Name is required</p>}
          </div>
        </div>
      </div>

      <div>
        <label htmlFor="email" className="form-label mt-3"><span>Email</span><span className="text-red-500">*</span></label>
        <input {...register('email', { required: true, pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/ })} className="form-input" />
        {errors.email && <p className="text-red-500">Email is required and must be valid</p>}
      </div>

      <div>
        <label htmlFor="organisation" className="form-label mt-3"><span>Organisation</span></label>
        <input {...register('organisation')} className="form-input" />
      </div>

      <div>
        <label htmlFor="country" className="form-label mt-3"><span>Country</span><span className="text-red-500">*</span></label>
        <select {...register('country', { required: true })} className="form-input">
          <option value="">Select your country...</option>
          {countries.map((country, index) => (
            <option key={index} value={country}>{country}</option>
          ))}
        </select>
        {errors.country && <p className="text-red-500">Country is required</p>}
      </div>

      <div>
        <label htmlFor="interest" className="form-label mt-3"><span>I am interested in</span><span className="text-red-500">*</span></label>
        <select {...register('interest', { required: true })} className="form-input">
          <option value="">Select...</option>
          {/* <option value="Speaking">Speaking</option> */}
          <option value="Attending">Attending</option>
          <option value="Partnering">Partnering</option>
        </select>
        {errors.interest && <p className="text-red-500">This field is required</p>}
      </div>

      <button type="submit" className="form-button mt-5 flex flex-row gap-2 items-center">
        Submit 
        {isLoading && <LoaderCircle className="animate-spin" />}
      </button>

      {formStatus && (
        <p className={`mt-3 ${formStatus.success ? 'text-green-500' : 'text-red-500'}`}>
          {formStatus.message}
        </p>
      )}
    </form>
  );
};

export default UserForm;