import React from 'react'
import useGoogleSheetData from "@/components/useGoogleSheet";
import { Button } from "@/components/ui/button"

export default function KeyDatesHome() {

const { data: data, loading, error } = useGoogleSheetData(
    "19hO6spkl18yL4YtUeIvv8E0ph05SAyiTSR3ByNsJNDQ",
    "Key Dates"
    );

return (
    <>
    <h2 className="mb-4 pb-0 font-medium">Key Dates</h2>
        {error && <div>Error: {error.message}</div>}
            {!loading && !error && data[0].data.map((item, index) => (
            <div key={index} className="border-b last:border-0">
                <p className="!font-anton text-lg text-gray-700 mb-0 mt-4">{item.title}</p>
                <p className="text-black mb-0">{item.date}</p>
                { item.venue &&
                <>
                <p className="text-black mb-0">{item.venue}</p>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                <Button className="p-2 text-sm bg-hturq-500 hover:bg-hturq-600 transition duration-500 ease-in-out mb-2">
                    {item.linktag}
                </Button>
                </a>
                </>}
            </div>
        ))}
    </>
)
}