import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import '../styles/global.css';
import Header from './header';
import Footer from './footer';
import BackToTop from './BackToTop';
import KeyDatesHome from '@/components/KeyDatesHome';

export default function Layout({ children }) {
  const location = useLocation();

  useEffect(() => {
    const observerCallback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => {
            if (node.nodeType === 1) {
              const elements = node.querySelectorAll('[data-radix-menubar-content]');
              elements.forEach(element => {
                if (element.parentElement) {
                  element.parentElement.classList.add('has-radix-menubar-content');
                }
              });
            }
          });
        }
      }
    };

    const observer = new MutationObserver(observerCallback);

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    document.body.classList.add('!mt-0');

    return () => {
      document.body.classList.remove('!mt-0');
    };
  }, []);

  // Determine if specific pages should have the classes
  const customPages = ["/"];
  const shouldHaveClasses = customPages.includes(location.pathname);

  return (
    <>
          <Header />
          <div className={`${!shouldHaveClasses ? '2xl:container mx-auto flex lg:flex-row flex-col xl:gap-12 gap:8 py-12 md:py-16' : ''}`}>
            <div className="xl:basis-3/4 lg:basis-2/3">
              {children}
            </div>
            { location.pathname != '/' && 
              <>
               <div className="xl:basis-1/4 lg:basis-1/3 bg-slate-100 mx-4 md:mx-6 mt-12 lg:mt-0 p-6 rounded-lg subpage-kd">
               <KeyDatesHome />
             </div>
             </>
            }
          </div>
          <Footer />
          <BackToTop />
    </>
  );
}
