import React from "react";
import { FacebookIcon, LinkedInIcon, YoutubeIcon, TwitterXIcon } from "@/components/icons";

export default function Socials() {
    return (
    <>
    <div className="space-y-8">
        <h2 className="text-white">Connect with us on Socials!</h2>

        <div>
            <h3 className="text-hyelo-400">ARC APAC Summit 2025</h3>
            <div className="flex flex-row gap-4 justify-start items-center">
                <div>
                    <a href="https://www.linkedin.com/company/australian-friends-of-sheba-medical-centre/?originalSubdomain=au" target="_blank" rel="noopener noreferrer"><LinkedInIcon className="w-12 h-12 text-gray-300 hover:text-gray-100" /></a>
                </div>
                <div>
                    <a href="https://www.facebook.com/ShebaAU/" target="_blank" rel="noopener noreferrer"><FacebookIcon className="w-12 h-12 text-gray-300 hover:text-gray-100" /></a>
                </div>
            </div>
        </div>

        <div>
            <h3 className="text-hyelo-400">ARC Innovation</h3>
            <div className="flex flex-row gap-4 justify-start items-center">
                <div>
                    <a href="https://www.linkedin.com/company/arc-innovation-center-at-sheba/" target="_blank" rel="noopener noreferrer"><LinkedInIcon className="w-12 h-12 text-gray-300 hover:text-gray-100" /></a>
                </div>
                <div>
                    <a href="https://x.com/ARC_Innovation_" target="_blank" rel="noopener noreferrer"><TwitterXIcon className="w-12 h-12 text-gray-300 hover:text-gray-100" /></a>
                </div>
                <div>
                    <a href="https://www.youtube.com/@ARCInnovationSheba" target="_blank" rel="noopener noreferrer"><YoutubeIcon className="w-12 h-12 text-gray-300 hover:text-gray-100" /></a>
                </div>
            </div>
        </div>
    </div>
    
    </>
    );
}
