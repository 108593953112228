import React from 'react'

export function HostLogos() {
    return (
        <>
        <div className="flex flex-col justify-center items-center">
            <img src="/images/in-partner.svg" width="250" className="text-center max-w-[150px]" />
        </div>
        <div class="mt-4 flex flex-row lg:gap-4 gap-2 justify-center items-center">
            <div className="lg:mx-2">
                <a href="https://www.melbournecb.com.au/" target="_blank" rel="noopener noreferrer"><img src="/images/MCB.png" className="h-10 sm:h-16" /></a>
            </div>
            <div className="lg:mx-2">
                <a href="https://www.vic.gov.au/" target="_blank" rel="noopener noreferrer"><img src="/images/Melbourne-Australia.png" className="h-10 sm:h-16" /></a>
            </div>
        </div>
        </>
    )
}

export function HostLogosPage() { 
    return (
        <>
           <div className="w-[500px] h-[288px] relative z-0">
                <div className="!absolute bottom-0 right-0 z-10 flex justify-end">
                    <img src="/images/triangle.png" width="500" height="288" />
                </div>
                <div className="">
                    <div className="w-[320px] p-4 absolute bottom-0 right-0 z-20">
                        <div><img src="/images/in-partner.svg" className="w-full max-w-[150px] ms-4" /></div>
                        <div class="mt-4 flex flex-row lg:gap-4 gap-2 justify-center items-center">
                            <div className="lg:mx-2">
                                <a href="https://www.melbournecb.com.au/" target="_blank" rel="noopener noreferrer"><img src="/images/MelbCB.svg" className="h-10 sm:h-16" /></a>
                            </div>
                            <div className="lg:mx-2">
                                <a href="https://www.vic.gov.au/" target="_blank" rel="noopener noreferrer"><img src="/images/Melb.svg" className="h-10 sm:h-16" /></a>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
        </>
    )
}