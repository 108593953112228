import React, { useState, useEffect } from 'react';
 
function CountdownTimer({ targetDate }) {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};
 
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0'),
        minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, '0'),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, '0'),
      };
    }
 
    return timeLeft;
  };
 
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
 
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
 
    return () => clearTimeout(timer);
  });
 
  return (
    <div className="flex flex-row justify-center">
      {timeLeft.days ? (
        <div className="time-container text-white text-center flex flex-col items-center pt-2 pb-4 rounded-lg w-24">
          <div className="time text-[3rem] lg:text-[4rem] font-medium text-white">{timeLeft.days}</div>
          <div className="date-text font-anton uppercase bg-hyelo-400 rounded-full px-4">days</div>
        </div>
      ) : null}
 
      {timeLeft.hours ? (
        <div className="time-container text-white text-center flex flex-col items-center pt-2 pb-4 rounded-lg w-24">
          <div className="time text-[3rem] lg:text-[4rem] font-medium text-white">{timeLeft.hours}</div>
          <div className="date-text font-anton uppercase bg-hyelo-400 rounded-full px-4">hours</div>
        </div>
      ) : null}
 
      {timeLeft.minutes ? (
        <div className="time-container text-white text-center flex flex-col items-center pt-2 pb-4 rounded-lg w-24">
          <div className="time text-[3rem] lg:text-[4rem] font-medium text-white">{timeLeft.minutes}</div>
          <div className="date-text font-anton uppercase bg-hyelo-400 rounded-full px-4">minutes</div>
        </div>
      ) : null}
 
      {timeLeft.seconds ? (
        <div className="">
          <div className="time-container text-white text-center flex flex-col items-center pt-2 pb-4 rounded-lg w-24">
            <div className="time text-[3rem] lg:text-[4rem] font-medium text-white">{timeLeft.seconds}</div>
            <div className="date-text font-anton uppercase bg-hyelo-400 rounded-full px-4">seconds</div>
          </div>
        </div>
      ) : null}
    </div>
  );
 
}
 
export default CountdownTimer;